import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "error-and-alert-messages"
    }}>{`Error and Alert Messages`}</h1>
    <p>{`Errors are “hard-stops,” or messages that prevent a user from moving forward in their workflow.
Alerts, or warnings, are “soft-stops” that convey information a user needs to know to prevent an
error, but don’t stop the user from continuing their workflow. In both cases, it’s important to make
the content of these messages clear, concise, and useful.`}</p>
    <h2 {...{
      "id": "general-guidelines"
    }}>{`General Guidelines`}</h2>
    <ul>
      <li parentName="ul">{`Write like a human speaks. How would you phrase the error if you were speaking to a friend? Read
your error out loud to test how natural your wording sounds.`}</li>
      <li parentName="ul">{`Try to tell the user what they need to do to fix the error. Only provide a solution when it is the
exact solution that will solve the issue.`}</li>
      <li parentName="ul">{`Let the user know what caused the error if that hasn’t been addressed by your fix. Try to balance
providing specific information with keeping the message concise.`}</li>
      <li parentName="ul">{`Be consistent across messages in the product. Errors of similar types should have similar
messages.`}</li>
    </ul>
    <h2 {...{
      "id": "active-voice"
    }}>{`Active Voice`}</h2>
    <p>{`Use active voice when writing error messages. You may use passive voice to avoid:`}</p>
    <ul>
      <li parentName="ul">{`Assigning fault to a user or 3rd party vendor.`}</li>
      <li parentName="ul">{`Negatively reinforcing the Workday brand.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`There was an issue retrieving your information.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Excellent Enterprise failed to retrieve your information.`}</li>
      </ul>
    </Dont>
    <p>{`However, in the majority of cases, you can keep messages positive, avoid blame, and stay in active
voice by telling users what they can do to recover.`}</p>
    <h2 {...{
      "id": "writing-tips"
    }}>{`Writing Tips`}</h2>
    <ul>
      <li parentName="ul">{`Use short, declarative, complete sentences in present tense.`}</li>
      <li parentName="ul">{`Use contractions to make the language more natural and casual.`}</li>
      <li parentName="ul">{`End full sentences with periods.`}</li>
      <li parentName="ul">{`Don't use special characters such as &, /, quotation marks, and so on. However, you can use tildas
(~) for custom labels and brackets (`}{`[ ]`}{`) for variables.`}</li>
      <li parentName="ul">{`Use second person. (“You don’t have permission to access this document.”)`}</li>
      <li parentName="ul">{`No need to segue or introduce the error. Don’t use “To fix this error . . .”`}</li>
      <li parentName="ul">{`Avoid jargon and technical language. Don’t use value when you mean number.`}</li>
      <li parentName="ul">{`Don’t use “please” or “sorry,” but try to keep the tone of the message polite and conversational.`}</li>
      <li parentName="ul">{`Don't label any messages with the words “Error” or “Alert.” This information is automatically
added to the beginning of error and alert messages.`}</li>
    </ul>
    <h2 {...{
      "id": "referring-to-field-labels"
    }}>{`Referring to Field Labels`}</h2>
    <p>{`Don’t include the field label associated with the error in the text of the message. Workday
automatically includes a subheading with the corresponding field label hyperlinked above the error
message text.`}</p>
    <p>{`If you directly refer to another UI element (which Workday doesn’t include as a subheading) ensure
the name exactly matches what’s in the UI, including capitalization. However, if you refer to UI
elements generally, use lower case.`}</p>
    <h2 {...{
      "id": "considerations-for-translations"
    }}>{`Considerations for Translations`}</h2>
    <p>{`When including variables, try to place them at the end of a message after a colon for ease in
translations. Include a noun that will help identify the variable somewhere in the message.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Enter a valid user. This user isn’t valid: `}{`[variable]`}{`.`}</li>
      </ul>
    </Do>
    <p>{`Try to limit variables and custom labels to only 1 or 2 per message, if possible. Overuse can lead
to confusing messages.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Select a remit-from ~sponsor~ that isn't already in use for at least 1 other ~sponsor~.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`You cannot select a remit-from ~sponsor~ for this ~sponsor~ because it is the remit-from ~sponsor~
for at least 1 other ~sponsor~.`}</li>
      </ul>
    </Dont>
    <h2 {...{
      "id": "more-error-message-examples"
    }}>{`More Error Message Examples`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Do`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Don't`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`You can't close the position if the job is available for overlap.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Close Position cannot be true if the job is available for overlap.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Select 1 instant messenger type as Primary.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One and only one instant messenger must be marked as Primary.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter corrected amounts before you submit.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`You cannot submit this time off request as you have not corrected any time off entries. Please enter an updated corrected request and submit the correction again.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`The worker has pending actions that conflict with this one. Complete the listed actions before starting the new action.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`You cannot initiate this action because there are other pending actions for the worker that conflict with this one. Close the pending actions listed below before initiating the new action.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter an End Date that's in the future.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The End Date must be after today.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter a Currency ID that isn't already in use.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Currency ID entered has already been used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter an As of Date that's on or after the first eligible period start date.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`As of Date must be greater than or equal to the first eligible period start date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter an Issue Date `}{`[issue date]`}{` that's on or before the expiration date `}{`[exp date]`}{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue date (`}{`[issue date]`}{`) cannot be after the expiration date (`}{`[exp date]`}{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Use a table for the selected element in this function: `}{`[variable]`}{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function `}{`[variable]`}{` selected element did not contain a table.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enter a valid date for the Expense Report Date field.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Error - Expense Report Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`We can't find the page you're looking for.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Page Not Found`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      